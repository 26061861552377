<template>
  <div data-test="warning-modal">
    <Window
      :modal="true"
      class="warning-modal"
      :class="[mq.phone ? 'mobile': '', mq.tablet ? 'tablet': '']"
      @close="onClose"
    >
      <template #header>
        <span v-t="'WarningModal.header'" />
      </template>

      <template #body>
        <div class="body">
          <BaseIcon name="exclamation-triangle" />
          <p
            class="context-message"
          >
            {{ warning.text }}
          </p>
        </div>
      </template>

      <template #footer>
        <div class="window-footer">
          <Button
            data-test="cancelButton"
            class="cancel-button"
            :label="$t('WarningModal.cancelButton')"
            @click="onClose"
          />
          <Button
            data-test="confirmButton"
            class="confirm-button"
            :label="$t('WarningModal.confirmButton')"
            @click="forceLastAction"
          />
        </div>
      </template>
    </Window>
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
export default {
  name: 'WarningModal',
  components: { BaseIcon },
  inject: ['mq'],
  computed: {
    warning () {
      return this.$store.getters['warningModal/firstWarning']
    },
    lastAction () {
      return this.warning.lastAction
    }
  },
  methods: {
    forceLastAction () {
      this.lastAction.fct.apply(this, this.lastAction.params)
      this.onClose()
    },
    onClose () {
      this.$store.dispatch('warningModal/removeFirstWarning')
    }
  }
}
</script>

<style lang="scss">
.warning-modal.mobile {
  .window-wrapper {
    max-width: 95vw;
    width: 100%;
  }
}
.warning-modal.tablet {
  z-index: 1001;
}
</style>

<style lang="scss" scoped>
  .body{
    padding: 10px;
  }

  .fa-exclamation-triangle{
    font-size: 50px;
    color: orange;
  }

  .warning-modal {
    display: flex;
  }

  .context-message{
    display: inline;
    vertical-align: 14px;
    padding-left: 10px;
  }

  .window-footer{
    display: flex;
    justify-content: space-evenly;
  }

  .cancel-button{
    flex: 1;
    min-width: 200px;
    height: 35px;
    font-weight: bold;
    font-size: 15px;
    background-color: gray;
    color: white;
  }

  .confirm-button{
    flex: 1;
    min-width: 200px;
    height: 35px;
    font-weight: bold;
    font-size: 15px;
    background-color: red;
    color: white;
  }

  .mobile {
    .window-footer{
      display: block;
      justify-content: normal;
    }

    .cancel-button{
      height: 45px;
      min-width: 0;
      width: 100%;
      margin-bottom: 5px;
    }

    .confirm-button{
      height: 45px;
      min-width: 0;
      width: 100%;
    }
  }
</style>
